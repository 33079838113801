<template>
  <v-app>
    <v-app-bar
      color="orange"
      app
      clipped-left
      v-if="
        $route.path.toString() !== '/'
      "
    >
      <v-row align="center">
        <v-col>
          <v-img
            src="~@/assets/straightcurve_logo_white.png"
            contain
            width="250px"
            @click="$router.push('/store')"
          ></v-img>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      disable-resize-watcher
      clipped
      color="orange"
      width="10%"
      permanent
      v-if="$route.path.toString() !== '/' && $route.path.toString().indexOf('view') === -1"
    >
      <v-list dense>
        <template v-for="(item, i) in items">
          <v-row v-if="item.heading" :key="i" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn small text>edit</v-btn>
            </v-col>
          </v-row>
          <v-divider
            v-else-if="item.divider"
            :key="i"
            dark
            class="my-4"
          ></v-divider>
          <v-list-item v-else :key="i" :to="item.link">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main :style="$route.path.toString().indexOf('order/') === -1 ? 'padding-top: 3rem;' : ''" class="grey lighten-3">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    drawer: null,
    items: [
      { text: "Store", link: "/store" },
      { text: "Orders", link: "/orders" },
      { text: "Account", link: "account" },
    ],
  }),
  mounted() {
    this.$vuetify.theme.themes.light.primary = "#ff9800";
  },
};
</script>

<style>
html {
  overflow-y: auto !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.nav-btn {
  color: white !important;
  text-decoration: underline;
  margin: 5px;
}
</style>