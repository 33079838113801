import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    maxHeight: 2200,
    smallestPalletHeight: 140,
    maxContainerWeight: 21500,
    smallestPalletWeight: 500,
    containers: [],
    filterCategory: '',
    filterSubCategory: '',
    filterSearch: '',
    selectedItemsOnly: false,
    steelFilter: 'Any',
    inventory: {},
    order: {
      orderNumber: '',
      orderNotes: '',
      total: 0,
      nails: 0,
      zincScrews: 0,
      dacrometScrews: 0,
      spaceRemaining: 100,
      items: [] //{product: '', quantity: 0, price: 0, total: 0, code: '', material: '', container: 0-4}
    },
    account: {},
    activeDraft: {}
  },
  getters: {
    isSelectedItemsOnly: (state) => {return state.selectedItemsOnly},
    amountInCart: (state) => (item) => {
      const cartItem = state.order.items.filter(i => i.code === item.code)[0];
      return cartItem ? cartItem.quantity : 0;
    },
    totalOrderWeight: (state) => {
      if (state.order.items.length === 0) return 0;
      return state.order.items.map(item => item.palletWeight).reduce((sum, x) => sum + x);
    },
    totalPalletCount: (state) => {
      if (state.order.items.length === 0) return 0;
      return state.order.items.map(item => item.quantity).reduce((sum, x) => sum + x);
    },
    remainingPalletCount: (state) => {
      let remainingPallets = 0;
      state.containers.forEach(container => {
        container.stacks.forEach(stack => {
          remainingPallets += Math.floor((state.maxHeight - stack.volume) / state.smallestPalletHeight);
        })
      })
      return remainingPallets;
    }
  },
  mutations: {
    loadInventory: (state, inventory) => state.inventory = inventory,
    loadAccount: (state, account) => state.account = account,
    resetOrder: (state) => state.order = { orderNumber: '', orderNotes: '', total: 0, nails: 0, zincScrews: 0, dacrometScrews: 0, spaceRemaining: 100, items: [] },
    removeItemFromCart: (state, item) => {
      const itemInCart = state.order.items.filter(i => i.code === item.code)[0];
      if (!itemInCart) return;
      itemInCart.total = 0;
      state.order.items.splice(state.order.items.findIndex(i => i.code === item.code), 1);

      const itemTotals = state.order.items.map(i => i.total);
      if (itemTotals.length > 0) state.order.total = itemTotals.reduce((sum, x) => sum + x);
      else state.order.total = 0;

      state.order.nails = state.order.nails - item.nails * itemInCart.quantity;
      state.order.zincScrews = state.order.zincScrews - item.zincScrews * itemInCart.quantity;
      state.order.dacrometScrews = state.order.dacrometScrews - item.dacrometScrews * itemInCart.quantity;
    },
    addItemToContainers: (state, item) => {
      //Add item to first available container
      containerLoop:
      for (let c in state.containers) {
        const container = state.containers[c];
        if (container.full) continue;
        if (container.volume + item.palletHeight <= state.maxHeight * 6 && container.mass + item.palletWeight < state.maxContainerWeight) {
          const stacks = container.stacks;
          for (let s in stacks) {
            const stack = stacks[s];
            if (stack.volume + item.palletHeight <= state.maxHeight) {
              stack.volume += item.palletHeight || 0;
              container.volume += item.palletHeight || 0;
              container.mass += item.palletWeight || 0;
              stack.items.push({ code: item.code, palletHeight: item.palletHeight });

              //Check if container is now full
              if (container.mass >= state.maxContainerWeight - state.smallestPalletWeight) container.full = true;
              else {
                let spaceAvailable = false;
                container.stacks.forEach((checkStack) => {
                  if (state.maxHeight - checkStack.volume >= state.smallestPalletHeight) spaceAvailable = true;
                });
                if (!spaceAvailable) container.full = true;
              }
              break containerLoop;
            }
          }
        }
      }
    },
    resetContainers: (state) => {
      state.containers = [
        {
          full: false,
          mass: 0,
          volume: 0,
          stacks: [
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
          ]
        },
        {
          full: false,
          mass: 0,
          volume: 0,
          stacks: [
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
          ]
        },
        {
          full: false,
          mass: 0,
          volume: 0,
          stacks: [
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
          ]
        },
        {
          full: false,
          mass: 0,
          volume: 0,
          stacks: [
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
          ]
        },
        {
          full: false,
          mass: 0,
          volume: 0,
          stacks: [
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
            { volume: 0, items: [] },
          ]
        },
      ]
    }
  },
  actions: {
    addItemToCart: ({ commit, state }, item) => {
      let itemAlreadyInCart = false;
      for (let i in state.order.items) {
        const itemInCart = state.order.items[i];
        if (item.code === itemInCart.code) {
          itemInCart.quantity++;
          itemInCart.total = itemInCart.price * itemInCart.quantity;
          itemAlreadyInCart = true;
          break;
        }
      }
      if (!itemAlreadyInCart) {
        const itemCopy = JSON.parse(JSON.stringify(item));
        itemCopy.quantity = 1;
        itemCopy.total = item.price;
        itemCopy.material = item.optionOneValue;
        state.order.items.push(itemCopy);
      }

      commit('addItemToContainers', item);

      state.order.total = state.order.items.map(item => item.total).reduce((sum, x) => sum + x);
      state.order.spaceRemaining -= 7;
      state.order.nails = +state.order.nails + item.nails;
      state.order.zincScrews = +state.order.zincScrews + item.zincScrews;
      state.order.dacrometScrews = +state.order.dacrometScrews + item.dacrometScrews;
    },
    deductItemFromCart: ({ state }, item) => {
      const itemInCart = state.order.items.filter(i => i.code === item.code)[0];
      if (!itemInCart) return;
      itemInCart.quantity--;
      itemInCart.total = itemInCart.quantity * itemInCart.price;
      if (itemInCart.quantity === 0) state.order.items.splice(state.order.items.findIndex(i => i.code === item.code), 1);
      const itemTotals = state.order.items.map(item => item.total);
      if (itemTotals.length > 0) state.order.total = itemTotals.reduce((sum, x) => sum + x);
      else state.order.total = 0;

      state.order.nails = state.order.nails - item.nails;
      state.order.zincScrews = state.order.zincScrews - item.zincScrews;
      state.order.dacrometScrews = state.order.dacrometScrews - item.dacrometScrews;
    },
    setItemInCart: ({ state, dispatch }, payload) => {
      const item = payload.item;
      const newQuantity = payload.quantity;

      const itemInCart = state.order.items.filter(i => i.code === item.code)[0];

      if (!itemInCart) {
        for (let i = 0; i < newQuantity; i++) dispatch('addItemToCart', item);
      } else {
        let isDeduction = newQuantity < itemInCart.quantity;
        const currentQuantity = itemInCart.quantity;
        if (!isDeduction) {
          for (let i = currentQuantity; i < newQuantity; i++) dispatch('addItemToCart', item);
        } else if (isDeduction) {
          for (let i = currentQuantity; i > newQuantity; i--) dispatch('deductItemFromCart', item);
          dispatch('reloadContainers');
        }
      }
    },
    loadOrder: ({ state, dispatch, commit }, orderId) => {
      const order = state.account.orders.filter(o => o.id === orderId)[0];
      console.log('order', order);

      //Set data needed by portal
      order.orderNumber = order.id;
      order.total = 0;
      order.nails = 0;
      order.zincScrews = 0;
      order.dacrometScrews = 0;
      order.spaceRemaining = 100;
      order.items = [];
      state.order = order;
      commit('resetContainers');
      order.lineItems.forEach(i => {
        const item = state.inventory.filter(inv => inv.code === i.code)[0];
        if (!item) return;
        for (let q = 0; q < i.qty; q++) {
          dispatch('addItemToCart', item);
        }
      });
    },
    loadDraftOrder: ({ state, dispatch, commit }, draftId) => {
      const draft = state.account.draftOrders.filter(draftOrder => draftOrder.id === draftId)[0];
      state.order = {
        orderNumber: draft.customerOrderNo,
        orderNotes: '',
        total: 0,
        nails: 0,
        zincScrews: 0,
        dacrometScrews: 0,
        spaceRemaining: 100,
        items: []
      };
      commit('resetContainers');
      draft.items.forEach(item => {
        console.log(`item ${item.product} quantity is ${item.quantity}`)
        for (let q = 0; q < item.quantity; q++) {
          console.log(`q: ${q} is < ${item.quantity}`)
          dispatch('addItemToCart', item);
        }
      });
      state.activeDraft = JSON.parse(JSON.stringify(state.order));
      state.activeDraft.name = draft.name;
      state.activeDraft.id = draft.id;
    },
    saveDraftOrder: ({ commit, state }, draftName) => {
      return new Promise((resolve, reject) => {
        fetch('https://script.google.com/macros/s/AKfycbz2cuD7I_9FJSDhAZvVuvuSE-FwpVgkPXJ4nh61TeP4Q17PYg-g/exec', {
          method: 'POST',
          body: JSON.stringify({
            id: state.activeDraft.id,
            username: state.account.username,
            name: draftName,
            customerOrderNo: state.order.orderNumber,
            items: state.order.items
          })
        }).then(response => response.json().then(res => {
          state.account.draftOrders.push({
            createdDate: new Date(),
            id: res.draftId,
            name: draftName,
            customerOrderNo: state.order.orderNumber,
            total: state.order.total
          })
          commit('resetOrder');
          resolve(res);
        })).catch(err => {
          console.log(err);
          reject(err);
        })
      })
    },
    reloadContainers: ({ commit, state }) => {
      commit('resetContainers');
      state.order.items.forEach(item => {
        for (let i = 0; i < item.quantity; i++) commit('addItemToContainers', item);
      })

    },
    loginUser: ({ commit, state }, user) => {
      return new Promise((resolve, reject) => {
        fetch(
          "https://script.google.com/macros/s/AKfycbz2cuD7I_9FJSDhAZvVuvuSE-FwpVgkPXJ4nh61TeP4Q17PYg-g/exec",
          {
            method: "POST",
            body: JSON.stringify({
              username: user.username,
              password: user.password,
            }),
          }
        )
          .then(response => response.json().then(json => {
            if (json.code === 200) {
              commit("loadAccount", json.account);
              commit("loadInventory", json.items);
              console.log('account', json.account);

              let smallestHeight = 500;
              let smallestWeight = 500;

              json.items.forEach(item => {
                if (item.palletHeight && item.palletHeight < smallestHeight) smallestHeight = item.palletHeight;
                if (item.palletWeight && item.palletWeight < smallestWeight) smallestWeight = item.palletWeight;
              });
              state.smallestPalletHeight = smallestHeight;
              state.smallestPalletWeight = smallestWeight;

              let currencySymbol = "$";
              switch (state.account.priceTier) {
                case "Australia AUD Excl": currencySymbol = "$"; break;
                case "NL EUR Exempt": currencySymbol = "€"; break;
                case "USA USD Exempt": currencySymbol = "$"; break;
                case "NZ NZD Exempt": currencySymbol = "$"; break;
                case "UK GBP Exempt": currencySymbol = "£"; break;
                case "NO NOK Exempt": currencySymbol = "kr"; break;
                case "SE SEK Exempt": currencySymbol = "kr"; break;
                case "DK DKK Exempt": currencySymbol = "Kr"; break;
              }
              state.account.currencySymbol = currencySymbol;
              commit('resetContainers');
              resolve();
            } else {
              reject(json.message);
            }
          })
          )
          .catch((err) => {
            console.log(err);
            reject("Something went wrong");
          });
      })

    }
  },
  modules: {}
});
